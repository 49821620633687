import React from 'react';

import b2cImage1 from 'assets/images/pages/ecommerce_b2cecommerce/1.jpg';
import b2cImage2 from 'assets/images/pages/ecommerce_b2cecommerce/2.jpg';
import b2cImage3 from 'assets/images/pages/ecommerce_b2cecommerce/3.jpg';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';

const EcommerceB2C = () => (
  <Layout>
    <Seo image={{ src: b2cImage1 }} />

    <Title
      image={{ src: b2cImage1 }}
      alt="B2C E-commerce"
      subtitle="Focus op de eindgebruiker"
      title="B2C E-commerce"
    >
      Heb of beheer je een business-to-consumer E-commerce platform of wil je
      deze misschien starten of verder professionaliseren? Dan ben je aan het
      juiste adres!
    </Title>

    <Section>
      <Image
        image={{ src: b2cImage2 }}
        alt="De B2C Magento developers van Batao"
      >
        <Heading size="xl" as="h2">
          De B2C Magento developers van Batao
        </Heading>

        <Text>
          Ons team met gepassioneerde Magento-developers is gespecialiseerd in
          zowel B2B als B2C E-commerce platforms. We beseffen ons dat beide
          typen platforms een totaal andere benadering nodig hebben. Onze
          marketeers, UX-designers en front-end designers zijn gespecialiseerd
          in het raken, inspireren en converteren van eindgebruikers. Zij staan
          er altijd op om jou en je bedrijf eerst goed te leren kennen, zodat ze
          de taal van jullie bedrijf en klanten nog beter leren spreken, zowel
          in woord als in beeld en gebruiksvriendelijkheid. Bekijk onze cases om
          te zien wat we voor eerdere klanten hebben gemaakt!
        </Text>
      </Image>
    </Section>

    <Section>
      <Image
        image={{ src: b2cImage3 }}
        alt="Wij kennen Magento door en door dankzij onze B2B modules"
        position="right"
      >
        <Heading size="xl" as="h2">
          Wij kennen Magento door en door dankzij onze B2B modules
        </Heading>

        <Text>
          Wij kennen Magento door en door omdat onze jarenlange ervaring zich
          niet alleen in het daadwerkelijk bouwen en onderhouden van
          business-to-business E-commerce platforms uit, maar ook in het
          ontwikkelen van kwalitatief hoogstaande B2B extensies voor Magento. De
          bekendste hiervan is ongetwijfeld Cart2Quote, onze offerte-module die
          door duizenden B2B wereldwijd wordt ingezet.
        </Text>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default EcommerceB2C;
